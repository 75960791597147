<template>
  <img
    :src="imgUrl"
    alt="Circle of Care Logo"
  >
</template>

<script>
/* eslint-disable global-require */
export default {
  data() {
    return {
      imgUrl: require('@/assets/images/logo/COC logo 247-Horizontal.svg'),
    }
  },
}
</script>
