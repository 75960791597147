<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-end justify-content-end p-0 bg-container"
      >
        <b-img
          fluid
          :src="imgUrl"
          alt="Login V2"
          style="width: 86.9%;"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to HomeCare Platform
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!--<b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>-->

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    :tabindex="tabindex"
                    name="login-email"
                    placeholder="email@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :tabindex="tabindex + 1"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                class="sign-in border-0"
                type="submit"
                variant="gradient-main"
                block
                :disabled="invalid || loggingIn"
              >
                {{ loggingIn === true ? 'Signing in...' : 'Sign in' }}
              </b-button>
            </b-form>
          </validation-observer>

          <!--<b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
          -->
          <!-- divider -->
          <!--<div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>
          -->
          <!-- social buttons -->
          <!--<div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>-->
        </b-col>
        <b-card-text class="font-small-1 login-footer">
          ©{{ currentYear }} Circle Of Care. All Rights Reserved. Powered by <b-link
            href="https://www.motivit.com"
            target="_blank"
          >MotivIT</b-link>
        </b-card-text>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, /* BAlert, */ VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    tabindex: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      // eslint-disable-next-line
      status: (localStorage.getItem('coc-rmpw') !== null && localStorage.getItem('coc-rmem') !== null) ? true : false,
      password: localStorage.getItem('coc-rmpw') !== null ? localStorage.getItem('coc-rmpw') : '',
      userEmail: localStorage.getItem('coc-rmem') !== null ? localStorage.getItem('coc-rmem') : '',
      sideImg: require('@/assets/images/pages/login-coc1.png'),
      currentYear: new Date().getFullYear(),
      loggingIn: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    setLoginuser(userId) {
      localStorage.setItem('last_login_user_id', userId)
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loggingIn = true
          // eslint-disable-next-line
          useJwt.axiosIns.post(this.$apiUrl2 + 'auth', {
            email: this.userEmail,
            pass: this.password,
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
            .then(response => {
              if (Object.prototype.hasOwnProperty.call(response.data.response.data, 'error')) {
                // console.log('error found')
                // this.$refs.loginForm.setErrors(response.data.response.data.error)
              } else {
                // console.log('error not found')
                // console.log(response.data.response.data.userData)
                // const { userData } = response.data.response.data.userData
                useJwt.setToken(response.data.response.data.accessToken)
                useJwt.setRefreshToken(response.data.response.data.refreshToken)

                if (this.status === true) {
                  localStorage.setItem('coc-rmem', this.userEmail)
                  localStorage.setItem('coc-rmpw', this.password)
                } else {
                  localStorage.removeItem('coc-rmem')
                  localStorage.removeItem('coc-rmpw')
                }

                localStorage.setItem('userData', JSON.stringify(response.data.response.data.userData))
                this.$ability.update(response.data.response.data.userData.ability)

                this.$store.commit('account_setting/SET_PROF_PIC', response.data.response.data.userData.avatar)
                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease

                const lastKnownRoute = JSON.parse(localStorage.getItem('lastKnownRoute')) || null

                if (lastKnownRoute?.name) {
                  if (canNavigate(lastKnownRoute)) {
                    const lastLoginUserId = JSON.parse(localStorage.getItem('last_login_user_id')) || null
                    if (lastLoginUserId === response.data.response.data.userData.id) {
                      this.setLoginuser(response.data.response.data.userData.id)
                      this.$router.push(lastKnownRoute)
                    } else {
                      this.setLoginuser(response.data.response.data.userData.id)
                      this.$router.go(getHomeRouteForLoggedInUser(response.data.response.data.userData.role))
                    }
                  } else {
                    const lastLoginUserId = JSON.parse(localStorage.getItem('last_login_user_id')) || null
                    if (lastLoginUserId === response.data.response.data.userData.id) {
                      this.setLoginuser(response.data.response.data.userData.id)
                      this.$router.push(getHomeRouteForLoggedInUser(response.data.response.data.userData.role))
                    } else {
                      this.setLoginuser(response.data.response.data.userData.id)
                      this.$router.go(getHomeRouteForLoggedInUser(response.data.response.data.userData.role))
                    }
                  }
                } else {
                  this.setLoginuser(response.data.response.data.userData.id)
                  this.$router.go(getHomeRouteForLoggedInUser(response.data.response.data.userData.role))
                }
                /* .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${response.data.response.data.userData.fullName || response.data.response.data.userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${response.data.response.data.userData.role}.`,
                    },
                  })
                }) */
              }
              this.loggingIn = false
            })
            // eslint-disable-next-line
            .catch(error => {
              // this.$refs.loginForm.setErrors(error.response.data.error)
              // console.log(error)
              this.loggingIn = false
              localStorage.setItem('error', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: 'Incorrect Email or Password',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
